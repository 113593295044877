<template>
  <div class="w-100">
    <form @submit.prevent="submitForm()" class="w-100">
      <div class="sidebar-card">
        <div class="p-3 bg-theme">
          <div v-if="responseStatus !== 200 && responseErrors" class="bg-theme-danger text-white p-3 my-2">
            <b>{{ $t("js.form.please_correct_the_following") }}</b>
            <ul class="mb-0">
              <li v-for="(errors, propertyName ) in responseErrors">
                <span v-for="error in errors">
                  {{ $t(`js.forms.candidacy.errors.${error.error}`, {
                    property:
                      $t(`js.properties.${item.type.toLowerCase()}.${propertyName}`)
                  }) }}
                </span>
              </li>
            </ul>
          </div>
          <div v-if="item.type === 'Amendment'">
            <h5>
              <div class="d-flex align-items-center">
                <i class="fas fa-file-signature me-1"></i>
                <select class="form-control" v-model="post.target_slide_id" required>
                  <option v-for="slide in ballotSlides" :value="slide.id" :selected="slide.id === post.target_slide_id">
                    {{ slide.title[firstAvailableLocale] }}</option>
                </select>
              </div>
            </h5>
            <span>{{ $t("js.sidebar.general.by") }} {{ item.owner.deleted ? $t("js.sidebar.general.deleted", {
              resource:
                $t(`js.sidebar.general.resource.${item.owner.type}`)
            }) : item.owner.name }}</span>
          </div>
          <div v-else-if="item.type === 'Candidacy'">
            <h5><i class="fas fa-user"></i> {{ item.owner.deleted ? $t("js.sidebar.general.deleted", {
              resource:
                $t(`js.sidebar.general.resource.${item.owner.type}`)
            }) : item.owner.name }}</h5>
            <div class="d-flex align-items-center">
              <span class="me-2">{{ $t("js.sidebar.general.for") }}</span>
              <select class="form-control" v-model="post.target_slide_id" required>
                <option v-for="slide in ballotSlides" :value="slide.id" :selected="slide.id === post.target_slide_id">
                  {{ slide.title[firstAvailableLocale] }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <textarea v-model="post.message" id="editMessage" type="text" class="form-control"
          style="border-radius: 0 0 0.25em 0.25em" rows="5"></textarea>
      </div>
    </form>
    <div class="bg-dark position-absolute rounded shadow-sm"
      style="border: 1px solid #565656;bottom: calc(100% - 0.5em);right: 0.5em; z-index: 100">
      <button class="btn btn-sm btn-link text-white" @click.prevent="submitForm()" v-tooltip="$t('js.actions.submit')">
        <i class="fas fa-check"></i>
      </button>
      <button class="btn btn-sm btn-link text-white" @click.prevent="$emit('closeForm')"
        v-tooltip="$t('js.actions.cancel')">
        <i class="fas fa-times"></i>
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapActions, mapState } from "pinia";
import { useSharedStore } from "@/entrypoints/stores/shared";
import { usePresentationStore } from "@/entrypoints/stores/presentation";
import { useVotingModulesStore } from "@/entrypoints/stores/voting_modules";
import type { PropType, UserPost, Slide } from "@/types";

export default defineComponent({
  name: "EditForm",
  props: {
    item: {
      type: Object as PropType<UserPost>,
      required: true,
    },
  },
  data() {
    return {
      post: {
        message: "",
        target_slide_id: null,
      },
      responseMessage: "",
      responseErrors: null,
      responseStatus: null,
      read: false,
    }
  },
  computed: {
    ...mapState(usePresentationStore, ["slides"]),
    ...mapState(useSharedStore, ["firstAvailableLocale"]),
    unread() {
      return this.item.unread;
    },
    ballotSlides() {
      return this.slides.filter((s: Slide) => s.type === "Slides::BallotSlide");
    },
  },
  methods: {
    ...mapActions(useVotingModulesStore, ["editPost", "markPostRead"]),
    ...mapActions(usePresentationStore, ["showToast"]),
    prepareEdit() {
      this.post = {
        message: this.item.message,
        target_slide_id: this.item.target_slide.id,
      }
      this.editForm = true;
      this.errors = [];
      this.$nextTick(function () {
        $("#message").focus();
      });
    },
    submitForm() {
      this.editPost({
        id: this.item.id,
        post: this.post
      }).then((res: any) => {
        if (res.status === 200) {
          this.$emit("closeForm");
        }
        this.responseMessage = res.data.message;
        this.responseStatus = res.status;

        this.showToast({
          header: this.$t(`js.toasts.updated.${this.item.type.toLowerCase()}`),
          body: "",
          duration: 5,
          classes: { header: "bg-theme" },
        });

        this.submitting = false;
      }).catch((error: any) => {
        this.responseStatus = error.response.status;
        this.responseErrors = error.response.data.errors;
        this.submitting = false;
      });
    },
  },
  mounted() {
    this.prepareEdit();
  },
});
</script>
