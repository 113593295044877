<template>
<button type="button" :disabled="buttonDisabled" @click="handleClick">
  <slot v-if="!waiting">
    <span>{{ $t("js.actions.submit") }}</span>
  </slot>
  <slot name="waiting" v-else>
    <IndeterminateSpinner/>
  </slot>
</button>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import IndeterminateSpinner from "@/components/backend/live/IndeterminateSpinner.vue";

export default defineComponent({
  components: { IndeterminateSpinner },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    waitTime: {
      type: Number,
      default: 0,
    },
    onClick: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    waiting: false,
  }),
  computed: {
    buttonDisabled() {
      return this.waiting || this.disabled;
    },
  },
  methods: {
    async handleClick(event: Event) {
      this.waiting = true;
      await new Promise(res => setTimeout(res, this.waitTime));
      try {
        await Promise.resolve(this.onClick(event));
      } catch (error) {
        console.error(error);
      } finally {
        this.waiting = false;
      }
    },
  },
});
</script>
