<template>
  <div class="d-flex h-100 flex-column overflow-auto">
    <TabHeader :header="$t('js.sidebar.headers.locales')" />
    <div class="h-100 p-3 bg-blur-dark">
      <button class="w-100 btn btn-theme mb-2" :class="{ active: locale === $i18n.locale }" href="#"
        @click="setLocale(locale)" v-for="locale in availableLocales">{{ $t("js.language_name", locale) }}
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import TabHeader from "@/components/frontend/voting/sidebar/TabHeader.vue";
import { useSharedStore } from "@/entrypoints/stores/shared";
import { mapActions, mapState } from "pinia";

export default defineComponent({
  name: "LocaleTab",
  components: { TabHeader },
  computed: {
    ...mapState(useSharedStore, ["locales"]),
    availableLocales() {
      return this.locales;
    },
    currentLocale() {
      return this.$i18n.locale;
    },
  },
  methods: {
    ...mapActions(useSharedStore, ["setLocale"]),
  },
});
</script>
