<template>
  <div class="position-relative" style="isolation: isolate">
    <button class="w-100 btn text-center position-relative rounded-0 py-3 overflow-hidden"
      :class="{ 'active': isActive, [color]: true }" style="line-height: 1em" @click="onClick">
      <i class="h5 mb-0 fas" :class="icon"></i><br>
      <span v-if="unreadCount" class="badge rounded-pill bg-theme-danger position-absolute" style="top: 15%; left: 60%">
        {{ unreadCount }}
      </span>
      <span class="circle pulse-button" v-if="pulse"></span>
      <span class="small visually-hidden">{{ text }}</span>
    </button>
    <div class="hover-label my-auto position-absolute m-auto d-grid align-items-center px-2">
      <small class="text-light bg-blur-darker border border-dark rounded text-nowrap p-2">
        {{ text }}
      </small>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "SideBarButton",
  emits: ["click"],
  props: {
    icon: {
      type: String,
      default: "",
    },
    unread: {
      type: Number,
      default: 0,
    },
    text: {
      type: String,
      default: "",
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "btn-theme",
    },
    pulse: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    unreadCount() {
      let count = null;
      count = this.unread > 0 ? this.unread : null;
      if (this.unread > 999) count = 999;
      return count;
    },
  },
  methods: {
    onClick() {
      this.$emit("click");
    },
  },
});
</script>

<style scoped lang="scss">
:hover>.hover-label,
:focus-within> :not(.active)+.hover-label {
  right: 100%;
  opacity: 1.0;
}

.hover-label {
  right: -100%;
  opacity: 0.0;
  transition: all 0.1s ease;
  top: 0;
  bottom: 0;
  z-index: -1;
}

html[dir=rtl] {

  :hover>.hover-label,
  :focus-within> :not(.active)+.hover-label {
    right: auto;
    left: 100%;
  }

  .hover-label {
    right: auto;
    left: -100%;
  }
}
</style>
