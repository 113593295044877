<template>
  <div v-if="roundFinished">
    <template v-if="votingRoundReport && roundFinished && votingRoundReport.publishedAt">
      <div class="p-3" v-for="(resultOrchestrator, index) in orderedOrchestrators">
        <hr class="mt-0" v-if="index > 0">
        <VotingRoundResult theme="light" :showTurnout="false" :result-orchestrator="resultOrchestrator"
          :contest="contests.find(contest => contest.reference === resultOrchestrator.contestReference)" />
      </div>
    </template>
    <div v-else class="p-3 text-center">
      <p class="mb-0">{{ $t("js.voting_round_modal.not_published") }}</p>
    </div>
  </div>
  <div v-else-if="votingRound" class="d-grid" style="height: 90vh">
    <iframe :src="embeddedElectionClientUrl" class="border-0" style="place-self: stretch" :key="reference"></iframe>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import VotingRoundResult from "@/components/backend/live/VotingRoundResult.vue";
import { mapActions, mapState } from "pinia";
import { calculateState, label } from "@/entrypoints/shared/voting_rounds_helper";
import { AVClient } from "@aion-dk/js-client";
import { useSharedStore } from "@/entrypoints/stores/shared";
import { usePresentationStore } from "@/entrypoints/stores/presentation";
import { useVotingSessionStore } from "@/entrypoints/stores/voting_session";
import type { PropType, ConferenceVotingRound, ConferenceVotingRoundReport } from "@/types";

export default defineComponent({
  name: "BallotEmbedded",
  components: { VotingRoundResult },
  props: {
    votingRound: {
      type: Object as PropType<ConferenceVotingRound>,
      required: true,
    },
  },
  computed: {
    ...mapState(useSharedStore, ["election"]),
    ...mapState(usePresentationStore, ["contests", "votingRoundReports"]),
    ...mapState(useVotingSessionStore, ["electionCodes"]),
    reference() {
      return this.votingRound ? this.votingRound.reference : null;
    },
    embeddedElectionClientUrl() {
      return `${this.election.electionClientUrl}/${this.$i18n.locale}/${this.votingRound.reference}/${this.electionCodesParams}`;
    },
    electionCodesParams() {
      return ":" + this.electionCodes.join(":");
    },
    roundFinished() {
      return this.votingRoundState === "finished";
    },
    votingRoundState() {
      return this.calculateState(this.votingRound, this.fetchVotingRoundReport(this.votingRound));
    },
    votingRoundReport() {
      return this.fetchVotingRoundReport(this.votingRound);
    },
    orderedOrchestrators() {
      const contestPositions = this.votingRound.contestPositions
      return this.votingRoundReport.resultOrchestrators.toSorted((a, b) =>
        contestPositions[a.contestReference] - contestPositions[b.contestReference]
      );
    }
  },
  methods: {
    label,
    calculateState,
    ...mapActions(usePresentationStore, ["setVotingRound"]),
    fetchVotingRoundReport(vr: ConferenceVotingRound) {
      return this.votingRoundReports.find((vrr: ConferenceVotingRoundReport) => vrr.votingRoundReference === vr.reference);
    },
    closeModal() {
      const votingRoundToExpire = this.votingRound;
      const finished = this.roundFinished;
      this.setVotingRound(null);

      if (!finished) this.cancelVoting(votingRoundToExpire);
    },
    async cancelVoting(votingRoundToExpire: ConferenceVotingRound) {
      try {
        const client = new AVClient(this.election.boardUrl, this.election.dbbPublicKey);
        await client.initialize();
        client.generateProofOfElectionCodes(this.electionCodes);
        await client.expireVoterSessions(votingRoundToExpire.reference);
      } catch (e) {
        console.error(e)
      }
    },
  },
});
</script>
