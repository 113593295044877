<template>
  <div class="d-flex p-3 justify-content-between align-items-center"
    :class="{ 'notification text-contrast': displayNotification, 'bg-blur-darker text-white': !displayNotification }">
    <transition>
      <div class="d-flex align-items-center" v-if="displayNotification">
        <button class="btn text-contrast" type="button" @click="setVisibleTab('Presentation')">
          <span class="fas fa-arrow-alt-circle-left"></span>
        </button>
        <span class="lead text-truncate">{{ $t(notification) }}</span>
      </div>
      <h5 v-else class="mb-0">{{ header }}</h5>
    </transition>
    <button class="btn btn-toned text-light d-sm-none" :class="{ 'text-contrast': displayNotification }"
      type="button" @click.prevent="$parent.$emit('showNav')">
      <i class="fas fa-bars"></i>
    </button>
  </div>
</template>

<script lang="ts">
import { mapState, mapActions } from "pinia";
import { defineComponent } from "vue";
import breakpoint from "@/entrypoints/shared/breakpoint";
import { usePresentationStore } from "@/entrypoints/stores/presentation";

export default defineComponent({
  name: "TabHeader",
  data: () => ({
    notification: null,
  }),
  watch: {
    activeSlide: function (newSlide, oldSlide) {
      if (oldSlide !== undefined) {
        if (newSlide !== oldSlide)
          this.notification = "js.sidebar.notifications.active_slide_changed";
      }
    },
    ballotState: function (newState, oldState) {
      if (oldState !== null)
        switch (newState) {
          case "open":
            this.notification = "js.sidebar.notifications.ballot_has_opened";
            break;
          case "countdown":
            this.notification = "js.sidebar.notifications.ballot_will_close";
            break;
          case "closed":
          case "new":
            this.notification = "js.sidebar.notifications.ballot_has_closed";
            break;
          case "finished":
            this.notification = "js.sidebar.notifications.ballot_has_finished";
            break;
        }
    },
  },
  props: {
    header: {
      type: String,
      default: "",
    },
  },
  methods: {
    ...mapActions(usePresentationStore, ["setVisibleTab"]),
  },
  computed: {
    ...mapState(usePresentationStore, ["activeSlide"]),
    fullscreen() {
      return breakpoint.less("sm");
    },
    ballotState() {
      return this.activeSlide ? this.activeSlide.state || null : null;
    },
    displayNotification() {
      return this.fullscreen && this.notification;
    },
  },
});
</script>